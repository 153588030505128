import _ from 'lodash';
import $ from 'jquery';
import moment from 'moment';
import { PERSISTENT_COOKIE_MAX_AGE } from '../../../../../shared/cookieAge';

AuthStateService.$inject = [
    '$state', '$rootScope', '$window', '$cookies', '$exceptionHandler', '$location', '$timeout',
    'Session', 'AuthTimeoutService', 'StateUtils', 'NoAccessModalService', 'AuthStatus', 'SsoService'
];

export default function AuthStateService(
    $state, $rootScope, $window, $cookies, $exceptionHandler, $location, $timeout,
    Session, AuthTimeoutService, StateUtils, NoAccessModalService, AuthStatus, SsoService
) {

    let willRedirectAfterLogin = false;

    /**
     * Checks user permissions and company options to see if going to a state is allowed.
     */
    // event, toState, toParams
    function checkStateAuthorization(transition) {
        const toState = transition.to();
        const params = Object.assign({}, transition.params());

        const { permission, isPublicPage, options } = toState.data;
        const company = Session.getCompany();
        const user = Session.getUser();

        if (toState.name === 'login' && $cookies.get('deeplink') && !willRedirectAfterLogin) {
            $cookies.remove('deeplink');
        }

        const permissionSpecified = permission != null;
        const optionsSpecified = options && options.length > 0;

        if (isPublicPage) {
            return;
        }
        if (AuthStatus.isAuthenticated()) {
            if (permissionSpecified && optionsSpecified) {
                if (user.hasPermission(permission) && company.hasAllOptions(options)) {
                    return;
                }
            } else {
                if (permissionSpecified) {
                    if (user.hasPermission(permission)) {
                        return;
                    }
                }
                if (optionsSpecified) {
                    if (company.hasAllOptions(options)) {
                        return;
                    }
                }
            }
            if (isAuthChildState(toState)) {
                if (!permissionSpecified && !optionsSpecified) {
                    return;
                }
            }
        }

        transition.abort(); // prevents state change
        console.warn('Access Denied!');

        if (AuthStatus.isAuthenticated()) {
            console.warn(`User does not have permission OR company does not have option`);
            if (!AuthTimeoutService.isAppLocked()) {
                const defaultState = StateUtils.getDefaultState();
                if (!_.isEqual(toState, defaultState)) {
                    return NoAccessModalService.open(toState);
                } else {
                    throw new Error('Setup error - user does not have permission to their own default page');
                }
            }
        } else {
            console.warn('User not Authenticated!');
            if (!AuthTimeoutService.isAppLocked()) {
                willRedirectAfterLogin = true;
                $cookies.put('deeplink', $location.url(), {
                    expires: moment().add(PERSISTENT_COOKIE_MAX_AGE, 'ms').toDate()
                });
                // legacy frame page allows any number of dynamic path and query params
                // this allows us to redirect to anything provided in a legacy frame url
                // TODO: see if there's a ui-router option to allow any number of params or a wildcard param
                if (toState.name === 'legacyFramePage') {
                    const legacyPath = $location.url();
                    params.urlPath = legacyPath.replace('/legacy-frame/', '');
                }
                $state.go('login', {
                    redirectTo: toState.name,
                    redirectParams: params
                });
            }
        }
    }

    /**
     * After logging in and required sign on tasks complete.
     */
    async function goToNextStateAfterLogin() {
        $rootScope.isRedirectingAfterLogin = true;
        let nextState;
        if ($state.params.redirectTo) {
            nextState = {
                name: $state.params.redirectTo,
                params: $state.params.redirectParams
            };
        } else if ($cookies.get('deeplink')) {
            nextState = StateUtils.getStateFromUrl($cookies.get('deeplink'));
        }
        const defaultState = StateUtils.getDefaultState();
        const fallbackState = StateUtils.getFallbackState();
        const statesToTry = nextState && !_.isEqual(nextState, defaultState)
            ? [nextState, defaultState, fallbackState] : [defaultState, fallbackState];
        for (const { name, params } of statesToTry) {
            try {
                await $state.go(name, params, { reload: true });
                break;
            } catch (err) {
                // Manually call $exceptionHandler because otherwise the error won't get recorded and no popup will show.
                $exceptionHandler(err);
            }
        }
        willRedirectAfterLogin = false;
        $rootScope.isRedirectingAfterLogin = false;
        $cookies.remove('deeplink');
    }

    /**
     * @param {Object} state
     * @returns {boolean}
     */
    function isAuthChildState(state) {
        return state.$$state().includes.auth;
    }

    /**
     * Determines where to go after user logs out.
     */
    async function redirectAfterLogout(screen) {
        const ssoEndpoint = SsoService.getSelectedEndpoint();
        if (ssoEndpoint && ssoEndpoint.logoutUrl) {
            if (!ssoEndpoint.logoutUrl.includes('/#/content/')) {
                $('#root').addClass('loading');
            }
            $window.location.href = ssoEndpoint.logoutUrl;
        } else {
            return $state.go('login', { screen }, { reload: true });
        }
    }

    /**
     * Redirects the user to where they originally wanted to go before logging in by SSO.
     */
    async function redirectAfterSso() {
        if (!$cookies.get('deeplink') && !$window.localStorage.getItem('msteams-deeplink')) {
            return;
        }
        await $timeout(0);
        try {
            const url = $cookies.get('deeplink') || $window.localStorage.getItem('msteams-deeplink');
            if (url.includes('isTeamsApp=1') || url.includes('isTeamsApp=true')) {
                $('body').addClass('is-teams-app');
            }
            return $location.url(url);
        } finally {
            $cookies.remove('deeplink');
        }
    }

    return {
        checkStateAuthorization,
        goToNextStateAfterLogin,
        redirectAfterLogout,
        redirectAfterSso
    };
}
