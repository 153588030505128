authRun.$inject = [
    '$rootScope', '$transitions', '$window', '$localStorage', 'AUTH_EVENTS', 'AuthStatus', 'AuthStateService', 'AuthTimeoutService',
    'Session', '$document'
];

export default function authRun(
    $rootScope, $transitions, $window, $localStorage, AUTH_EVENTS, AuthStatus, AuthStateService, AuthTimeoutService,
    Session, $document
) {

    Session.preventLocalStorageReset('isAuthenticated');

    // $window.rnSession.isAuthenticated --> server sees the user as logged in
    // !$localStorage.isAuthenticated --> user wasn't logged in last time they were on the site (or they never went)
    const serverLoggedUserIn = $window.rnSession.isAuthenticated && !$localStorage.isAuthenticated;
    if (serverLoggedUserIn) {
        AuthStateService.redirectAfterSso();
    }

    // Updates $rootScope, $localStorage
    AuthStatus.setIsAuthenticated($window.rnSession.isAuthenticated);

    if (AuthStatus.isAuthenticated()) {
        AuthTimeoutService.startTimeout();
    }

    $rootScope.$on(AUTH_EVENTS.LOCK_APP, () => AuthStatus.setIsAuthenticated(false));

    $rootScope.$watch(() => $localStorage.isAuthenticated, (newValue, oldValue) => {
        if (newValue !== oldValue) {
            AuthStatus.setIsAuthenticated(newValue);
        }
    });

    $transitions.onSuccess({ from: 'games' }, checkGameIsOutstanding);

    function checkGameIsOutstanding() {
        const user = Session.getUser();
        if (user && user.playerToken) {
            if (window.iziigame) {
                window.iziigame.isIncompleteGameplays(user.playerToken).then(function(result) {
                    console.log('nav isIncompleteGameplays successful');
                    if (result) {
                        $document[0].body.classList.add('is-gameplay');
                    } else {
                        $document[0].body.classList.remove('is-gameplay');
                    }
                }, function(err) {
                    console.log('nav isIncompleteGameplays failed');
                    console.log(err);
                    $document[0].body.classList.remove('is-gameplay');
                });
            } else {
                console.error('missing iziigame.js, cannot call isIncompleteGameplays.');
                $document[0].body.classList.remove('is-gameplay');
            }
        }
    }

    $transitions.onBefore({}, AuthStateService.checkStateAuthorization);
}
