loginDialogCtrl.$inject = ['$window'];

export default function loginDialogCtrl($window) {
    const vm = this;
    vm.reload = reload;
    
    function reload() {
        $window.location.reload();
    }
}
