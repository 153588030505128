// TODO: use only one instance of navCtrl for header, footer, and sidebar. See nav.config.js
// (since sidebar can have both header and footer items, it would prevent duplicate logic and be more flexible)

import $ from 'jquery';

quickLinksCtrl.$inject = [
    'quickLinks', 'NavService', '$scope', '$translate','StateUtils', 'LangUtils', 'AuthStatus', 'AUTH_EVENTS', 'Session', 'SkinUtils'
];

export default function quickLinksCtrl(
    quickLinks, NavService, $scope, $translate, StateUtils, LangUtils, AuthStatus, AUTH_EVENTS, Session, SkinUtils
) {
    const vm = this;

    vm.nav = quickLinks;
    vm.company = Session.getCompany();
    vm.subDomain = vm.company.subDomain;
    vm.langCode = LangUtils.getLang().toLowerCase();

    if (AuthStatus.isAuthenticated()) {
        onAuthenticated();
    } else {
        onNotAuthenticated();
    }

    $scope.$on(AUTH_EVENTS.LOGIN_SUCCESS, onAuthenticated);

    $scope.$on(AUTH_EVENTS.LOGOUT_SUCCESS, onNotAuthenticated);

    // TODO: consider separate auth and no auth nav controllers if this many variables need to change

    function onAuthenticated() {
        vm.isAuthenticated = true;
        vm.nav = NavService.getNavLinks();
        vm.user = Session.getUser();
        vm.user.hasNegativePoints = Number(vm.user.points) < 0;
        vm.spectator = Session.getSpectator();
        vm.skinCode = SkinUtils.getSkinCode();
        vm.defaultUiSref = StateUtils.getDefaultStateUiSref();
    }

    function onNotAuthenticated() {
        vm.isAuthenticated = false;
        vm.nav = NavService.getNavLinks();
        delete vm.user;
        vm.skinCode = SkinUtils.getSkinCode();
        vm.defaultUiSref = 'login';
    }

    // Header
    // ----------------------------------------------------------------------------------------------


    vm.showContactPanel = false;
    vm.toggleShowContactPanel = function() {
        vm.showContactPanel = !vm.showContactPanel;
    };

    vm.closeContactPanel = function() {
        vm.showContactPanel = false;
    };


    // Footer
    // ----------------------------------------------------------------------------------------------

    vm.refreshTranslation = $translate.refresh;

}
