import _ from 'lodash';

spotlightItemToolsCtrl.$inject = ['SpotlightService', 'Prompt', 'Session', 'likesModal', 'MessageService', '$timeout', '$element'];

export default function spotlightItemToolsCtrl(SpotlightService, Prompt, Session, likesModal, MessageService, $timeout, $element) {
    this.$onInit = () => {
        const vm = this;

        /* Assume vm.item has:
            - isLikedByUser
            - totalLikes
            - socialComments
            - totalComments */

        _.defaults(vm, {
            onToggleLike: _.noop,
            onToggleComment: _.noop,
            onDelete: _.noop,
            showSpotlightButtons: true,
            showDeleteButton: true,
            showPrintButton: true,
            isMasonryPage: false
        });

        vm.user = Session.getUser();
        vm.company = Session.getCompany();

        vm.canCommentAndLike = vm.company.hasOption('CommentsAndLikes');

        vm.item.isLikesListRequested = false;
        vm.item.isLikesListLoaded = false;
        vm.item.LikedUsers = [];

        vm.toggleLike = async () => {
            await (vm.item.isLikedByUser ? unlike() : like());
            return vm.onToggleLike();
        };

        vm.toggleCommentForm = () => {
            vm.showCommentForm = !vm.showCommentForm;
            vm.onToggleComment();
        };


        vm.delete = async() => {
            await Prompt.open({ type: 'warning' }).result;
            return vm.onDelete();
        };

        vm.canDelete = () => {
            if (vm.item.applauseID) {
                return vm.user.hasPermission('DeleteApplause');
            }
            if (vm.item.certificateID) {
                return vm.user.hasPermission('DeleteCertificate');
            }
            if (vm.item.socialPostID) {
                return vm.user.hasPermission('SocialStreamDeleteOther') || vm.user.userID === vm.item.fromContact.userID;
            }
        };

        vm.print = async () => MessageService.printMessage(vm.item);

        const like = async () => {
            await SpotlightService.likeItem(vm.item);
            vm.item.isLikedByUser = true;
            vm.item.totalLikes += 1;

            // Start the Lottie animation
            let animationID = "likeAnimation" 
            if (vm.item.messageRecipientID) {
                animationID = animationID + '-message-recipient-' +vm.item.messageRecipientID 
            } else {
                animationID = animationID + '-social-post-' + vm.item.socialPostID 
            }

            $timeout(() => {
                const animationElement = $element.find(`[ref="${animationID}"]`)[0];
                if (animationElement) {
                    animationElement.stop();
                    animationElement.play();
                }
            }, 0);


        };

        const unlike = async () => {
            await SpotlightService.unlikeItem(vm.item);
            vm.item.isLikedByUser = false;
            vm.item.totalLikes -= 1;
        };


        vm.likeList = async () => {
            if(vm.item.isLikesListRequested) {
                vm.item.isLikesListRequested = false;
                vm.item.isLikesListLoaded = false;
            } else {
                vm.item.isLikesListRequested = true;
                vm.item.LikedUsers = await SpotlightService.likeList(vm.item);
                vm.item.isLikesListLoaded = true;
            }

            if(vm.isMasonryPage) {
                const modalInstance = likesModal.open({
                    item: vm.item
                });

                modalInstance.result.finally(() => {
                    vm.item.isLikesListRequested = false;
                    vm.item.isLikesListLoaded = false;
                });
            }
        
        };

    };
}
